import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../utils.service";
import {LocalStorageService} from "../../../../local-storage.service";
import {PromotionApiResourceV2} from "../../../../generated/cronos/resources";
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {SeminarCheckoutService} from "../../seminar-checkout.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SeminarCheckoutS6ProcessService} from "../seminar-checkout-s6-process.service";
import {PublicFormatPageData} from "../../../../generated/cms/data";
import {
  EnterPromotionContactData,
  PublicPromotionFormatData,
  PublicSeminarData
} from "../../../../generated/cronos/data";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'app-seminar-checkout-s6-user',
  templateUrl: './seminar-checkout-s6-user.component.html',
  styleUrls: ['./seminar-checkout-s6-user.component.scss']
})
export class SeminarCheckoutS6UserComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;

  page: PublicFormatPageData;
  pageSubscription: Subscription = new Subscription()
  user: EnterPromotionContactData

  flavourTexts: string[] = []

  selectedSeminar: PublicSeminarData

  loading: boolean = true

  constructor(private fb: FormBuilder,
              private utilsService: UtilsService,
              public localStorageService: LocalStorageService,
              private promotionApiResourceV2: PromotionApiResourceV2,
              public globalCheckoutService: GlobalCheckoutService,
              public seminarCheckoutService: SeminarCheckoutService,
              private router: Router,
              private s6ProcessService: SeminarCheckoutS6ProcessService,
              private route: ActivatedRoute
  ) {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      acceptPrivacy: ['', Validators.requiredTrue],
      acceptAdditionalTerms: [''],
      studyEndYear: [''],
      phoneNumber: [null, [this.utilsService.validatePhoneNumber(), Validators.required]]
    });

    this.selectedSeminar = this.seminarCheckoutService.getSeminar()
  }

  checkoutAttempted = new BehaviorSubject<boolean>(false);
  additionalTermsRequired = false;

  ngOnInit() {
    if (!this.selectedSeminar?.id) {
      this.back();
      return;
    }

    this.s6ProcessService.reportTrackingEvent('ContactDataReached');

    this.pageSubscription = this.globalCheckoutService.currentCheckoutPage.subscribe(next => {
      if (next) {
        this.page = next
        this.loading = false
      }
    })

    this.user = this.globalCheckoutService.getCurrentUser()
    this.globalCheckoutService.disableForwarding()

    this.promotionApiResourceV2.getPromotionFormat(this.selectedSeminar.formatId).then(result => {
      this.getFormatSpecificFlavourText(result)
      this.additionalTermsRequired = result.showConditionsOfParticipation
    });

    window.scrollTo(0, 0);
    //If competition we need to display terms
    if (this.additionalTermsRequired) {
      this.contactForm.controls.acceptAdditionalTerms.setValidators([Validators.requiredTrue])
    }

    //Init the form with given contactdata
    this.contactForm.controls['firstName'].setValue(this.user.firstName);
    this.contactForm.controls['lastName'].setValue(this.user.lastName);
    this.contactForm.controls['email'].setValue(this.user.email);
    this.contactForm.controls['phoneNumber'].setValue(this.user.phoneNumber);
    this.contactForm.controls['studyEndYear'].setValue(this.user.studyEndYear);


  }

  ngOnDestroy() {
    this.pageSubscription?.unsubscribe()
  }

  getFormatSpecificFlavourText(formatData: PublicPromotionFormatData){
    switch (formatData.id){
      //steuer
      case 2214:{
        this.flavourTexts = ["Steuer-Bundle inkl. Anleitung zur 1. Steuererklärung", "Chance auf ein neues iPad (10. Generation)"]
        return
      }
      //thesis
      case 2215: {
        this.flavourTexts = ["Thesis-Bundle inkl. formatierter Thesis-Vorlage"]
        // requested by marketing to not show text when the splitId is 34826
        if (this.page.selectedSplitId != 34826) this.flavourTexts.push("Gutschein für eine Plagiatsprüfung")
        return
      }
      //excel
      case 2216: {
        this.flavourTexts = ["Persönliches Excel-Zertifikat", "Cheat Sheet mit den wichtigsten Formeln & Shortcuts"]
        return
      }
      //fined
      case 2237: {
        this.flavourTexts = ["Finanzplaner für dein Studium", "Chance auf ein neues iPad (10.Generation)"]
        return
      }
      //fined4Women
      case 2338:{
        this.flavourTexts = ["Finanzplaner für dein Studium"]
        return
      }
      default: {
        this.flavourTexts = []
      }
    }
  }

  attemptCheckout(){
    this.checkoutAttempted.next(true)
    if(!this.contactForm.valid)return
    this.globalCheckoutService.fillUserData(<EnterPromotionContactData>{
      firstName: this.contactForm.controls['firstName'].value,
      lastName: this.contactForm.controls['lastName'].value,
      email: this.contactForm.controls['email'].value,
      phoneNumber: this.contactForm.controls['phoneNumber'].value,
      studyEndYear: this.contactForm.controls['studyEndYear'].value,
      privacyConsentGiven: true //consent is mandatory input field
    })
    this.loading = true;
    this.s6ProcessService.reportTrackingEvent('ContactDataCompleted');
    this.seminarCheckoutService.completeCheckout(this.s6ProcessService.splitIdentifier);
  }

  back(){
    this.router.navigate([this.s6ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/seminars/s6/date')]);
  }

}
