import {Component, OnInit} from '@angular/core';
import {PublicFormatPageData} from '../../../../generated/cms/data';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalCheckoutService} from '../../../global-checkout.service';
import {SeminarCheckoutService} from '../../../seminar-checkout/seminar-checkout.service';
import {StandardCheckoutS2ProcessService} from '../standard-checkout-s2-process.service';

@Component({
  selector: 'app-sta-checkout-s2-location',
  templateUrl: './sta-checkout-s2-location.component.html',
  styleUrls: ['./sta-checkout-s2-location.component.scss']
})
export class StaCheckoutS2LocationComponent implements OnInit {



  page: PublicFormatPageData;

  constructor(private router: Router, public globalCheckoutService: GlobalCheckoutService,
              private seminarCheckoutService: SeminarCheckoutService,
              private standard2ProcessService: StandardCheckoutS2ProcessService,
              private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    if(this.checkForForewarding()){
      this.reportReached(true)
      this.next(true);
      return
    }
    this.reportReached(false)

    window.scrollTo(0, 0);
    this.globalCheckoutService.disableForwarding()
  }

  reportReached(skip: boolean){
    this.standard2ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding(){
    if(!this.globalCheckoutService.forwardingEnabled)return false
    //location has already been selected and stored, possibly in a prior checkout
    if(this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null)return true
    return false
  }

  next(skip: boolean = false) {
    let zipCode = this.globalCheckoutService.getCurrentUser().zipCode
    this.standard2ProcessService.reportTrackingEvent('LocationSelected',
      {'skip': JSON.stringify(skip), 'locationZipCode': zipCode});
    this.router.navigate([this.standard2ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/standard/s2/user')]);
  }
}
