import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RadioOption} from "../../../../hid-interactions/hid-radio-group/hid-radio-group.component";
import {MatDialog} from "@angular/material/dialog";
import {
  StudentConfirmPresentDialogComponent
} from "../student-confirm-present-dialog/student-confirm-present-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {CreateReasonBasedSeminarFeedbackData, RegistrationInfoData} from "../../../../generated/cronos/data";
import {
  ContactSupportModalComponent
} from "../../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilsService} from "../../../../utils.service";
import {RegistrationInfoService} from "../../registration-info.service";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {DateUtilsService} from "../../../../inno-utils/dateutils.service";

@Component({
  selector: 'app-seminar-student-not-present-feedback',
  templateUrl: './seminar-student-not-present-feedback.component.html',
  styleUrls: ['./seminar-student-not-present-feedback.component.scss']
})
export class SeminarStudentNotPresentFeedbackComponent implements OnInit {

  reasons = ['AppointmentForgotten', 'LocationNotFound', 'NotInterested', 'Miscellaneous']
  recommendedUpsellPageIds: number[] = [];

  feedbackSend: boolean = false;
  feedbackDataLoaded: boolean = false;
  errorMessage: boolean = false;

  feedbackGroup: FormGroup;
  token: string = '';
  seminarFeedbackPageData: RegistrationInfoData = <RegistrationInfoData>{};
  seminarMissedFeedbackData: CreateReasonBasedSeminarFeedbackData = <CreateReasonBasedSeminarFeedbackData>{};
  seminarFeedbackPageDataLoading: boolean = true;

  radioOptionsForOfflineSeminar: RadioOption[] = [
    {value: 'AppointmentForgotten', display: 'Ich habe den Termin vergessen'},
    {value: 'CanceledSeminar', display: 'Seminar wurde vom Referent abgesagt'},
    {value: 'LocationNotFound', display: 'Ich habe den Ort nicht gefunden'},
    {value: 'NotInterested', display: 'Ich hatte kein Interesse mehr'},
    {value: 'Missed', display: 'Ich war verhindert'},
    {value: 'Miscellaneous', display: 'Sonstiges'}];

  radioOptionsForWebinar: RadioOption[] = [
    {value: 'AppointmentForgotten', display: 'Ich habe den Termin vergessen'},
    {value: 'CanceledSeminar', display: 'Seminar wurde vom Referent abgesagt'},
    {value: 'NotInterested', display: 'Ich hatte kein Interesse mehr'},
    {value: 'Missed', display: 'Ich war verhindert'},
    {value: 'TechnicalIssues', display: 'Ich hatte technische Probleme beim Beitritt'},
    {value: 'Miscellaneous', display: 'Sonstiges'}
  ]

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private seminarProcessAPIResourceV2: SeminarProcessAPIResourceV2,
    private utilsService: UtilsService,
    private promotionResource: PromotionApiResourceV2,
    private registrationInfoService: RegistrationInfoService,
    private offerPageResource: PublicOfferPageResource,
    public dateUtils: DateUtilsService
  ) {
  }

  ngOnInit() {
    this.initializeForm()
    this.getTokenInformation()
  }

  openConfirmDialog() {
    let dialogRef = this.dialog.open(StudentConfirmPresentDialogComponent, {
      data: { registrationInfo: this.seminarFeedbackPageData, token: this.token},
      maxWidth: '100%',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) this.router.navigateByUrl('actions/feedback/student-present-feedback/token?code=' + this.token)
    })
  }

  initializeForm() {
    this.feedbackGroup = this.fb.group({
      studentWasPresent: [null],
      reason: [null, Validators.required],
      reasonText: [null]
    })
  }

  getTokenInformation() {
    this.seminarFeedbackPageDataLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.seminarFeedbackPageData = result;
      this.feedbackDataLoaded = true;

      if (this.seminarFeedbackPageData.promotionData && this.seminarFeedbackPageData.promotionData.promotionType == 'OfflineSeminarDate') {
        this.seminarFeedbackPageData.promotionData.address = this.utilsService.deleteCityNameOutOfAddress(this.seminarFeedbackPageData.promotionData.location, this.seminarFeedbackPageData.promotionData.address)
      }
      this.seminarFeedbackPageDataLoading = false;

      if (this.seminarFeedbackPageData.promotionData) this.getRecommendedOfferPageIds();

    })
  }

  sendFeedback() {
    if (this.feedbackGroup.valid) {
      this.errorMessage = false;
      this.feedbackSend = true;
      this.seminarMissedFeedbackData.comment = this.feedbackGroup.value.reasonText;
      this.seminarMissedFeedbackData.reason = this.feedbackGroup.value.reason.value;
      this.promotionResource.enterReasonBasedSeminarFeedback(this.seminarMissedFeedbackData, this.token).then(result => {
         this.router.navigateByUrl('actions/feedback/not-present-thank-you?code=' + this.token);
      })
    } else {
      this.errorMessage = true
    }
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.seminarFeedbackPageData.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }
}
