export const environment = {
  production: true,
  cmsApiUrl: 'https://$$CMS_API_HOST$$',
  cronosApiHost: '$$CRONOS_API_HOST$$',
  cronosApiUrl: 'https://$$CRONOS_API_HOST$$',
  talentAgentPublicFrontendUrl: "https://$$TALENTAGENT_PUBLIC_FRONTEND_URL$$",
  talentAgentFrontendUrl: "https://$$TALENTAGENT_FRONTEND_URL$$",
  talentAgentApiUrl: "https://$$TALENTAGENT_API_HOST$$",
  googleReviewPage: '$$GOOGLE_REVIEW_PAGE$$',
  cronosSourceToken: '3N6REYG57ZURJNHFEKLYDW6VDTZBWPAG',
  gtmId: '$$GTM_ID$$',
  appVersion: "1169",
  piwikContainerUrl: '$$PIWIK_CONTAINER_URL$$',
  piwikPageId: '$$PIWIK_PAGE_ID$$',
};
