import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  ActionLinkAPIResourceV2,
  ContactApiResourceV2,
  PromotionApiResourceV2
} from "../../../../generated/cronos/resources";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {
  ContactSupportModalComponent
} from "../../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {MediaService} from "../../../../inno-utils/media.service";
import {LocalStorageService} from "../../../../local-storage.service";
import {
  NoTokenRedirectDialogComponent
} from "../../../../online-lecture/no-token-redirect-dialog/no-token-redirect-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {delay, UtilsService} from "../../../../utils.service";
import {WebinarJoinInfoData} from "../../../../generated/cronos/data";
import Bugsnag from '@bugsnag/js';
import {ClipboardService} from "ngx-clipboard";
import {HidSnackbarService} from "../../../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {environment} from 'src/environments/environment';
import {AnalyticsService} from "../../../../analytics/analytics.service";
import {PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

@Component({
  selector: 'app-enter-webinar',
  templateUrl: './enter-webinar.component.html',
  styleUrls: ['./enter-webinar.component.scss']
})
export class EnterWebinarComponent implements OnInit {

  token: string;
  state: boolean;
  cronosFormatId;

  iteminfo: WebinarJoinInfoData;
  recommendedUpsellPageIds: number[] = []

  loadingInfo = true;
  navigating = false;
  downloaded = false;

  step: 'enterCode' | 'enterWebinar' | 'enterAgain ' | 'delayForHint' = 'enterWebinar';

  checkingCode: boolean = false;
  codeInputFromUser: FormControl<string> = new FormControl(null, [
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.required
  ]);

  interval;
  days: number = 0;
  months: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;

  offerPageUrl;
  offerPageId;

  savingPhoneNumber: boolean = false;


  seminarId;
  localSplitWasActive;
  waitingListSignup;
  webinarIsJoinable: boolean = false;

  phoneUtil = PhoneNumberUtil.getInstance()
  phoneNumberControl = new FormControl(null, [this.utilsService.validatePhoneNumber(), Validators.required]);

  joinlink = '';

  allowedToJoin = false;

  showFullnameNotification: boolean = false;

  fadeOutNotification: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private modal: NgbModal,
    public mediaService: MediaService,
    private cronosApi: PromotionApiResourceV2,
    private contactAPIResourceV2: ContactApiResourceV2,
    private actionLinkResource: ActionLinkAPIResourceV2,
    private offerPageResource: PublicOfferPageResource,
    public localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private publicOfferPageResource: PublicOfferPageResource,
    public utilsService: UtilsService,
    private clipboard: ClipboardService,
    private fb: FormBuilder,
    private snackbar: HidSnackbarService,
    @Inject("env") private environment,
    private analyticsService: AnalyticsService
  ) {

    this.codeInputFromUser.valueChanges
      .subscribe(() => {
        this.checkCode();
      })

  }

  ngOnInit() {

    this.route.snapshot.params.code ? this.token = this.route.snapshot.params.code : this.token = this.route.snapshot.params.token;
    this.state = (this.route.snapshot.params.state === 'true');
    this.cronosFormatId = this.route.snapshot.params.cronosFormatId;

    this.getItemInfo();

    this.interval = setInterval(x => {
      if (this.iteminfo) this.getExpiryTime();
    }, 1000)

    delay(400).then(() => {
      this.showFullnameNotification = true
    })

  }


  linkError = false;


  getItemInfo() {
    this.loadingInfo = true;
    if (!this.token) {
      this.redirectToOfferPage();
    } else {
      this.cronosApi.getWebinarInfosByToken({code: this.token}).then(result => {
        this.iteminfo = result;
        if(result.tripleOptInned) {
          this.updateTripleOptInInDataLayerAndTriggerPageView();
        }


        if (!result.tripleOptInned && this.route.snapshot.queryParams.phoneAuthCode) {
          this.actionLinkResource.validatePhoneNumberByPromRegStateCode({code: this.token, authCode: this.route.snapshot.queryParams.phoneAuthCode}).then(result => {
            if (!result.validated) {
              this.allowedToJoin = false
            } else {
              this.allowedToJoin = true;
              this.step = 'enterWebinar';
              this.updateTripleOptInInDataLayerAndTriggerPageView();
            }
          })
        }

        this.allowedToJoin = this.allowedToJoin || result.tripleOptInned;

        if (!this.allowedToJoin) {
          this.step = 'enterCode';
          this.loadPhoneNumber();
        }

        if (result.webinarLink == null || result.webinarLink.length < 1) {
          this.linkError = true;
          let releaseStage = 'production'
          if (!environment.production) {
            releaseStage = 'development'
          }
          Bugsnag.notify('Webinarlink for webinar ' + result.id + ' is null')
        }
        if (this.iteminfo.webinarLink.toLowerCase().indexOf('https://') == -1 && this.iteminfo.webinarLink.toLowerCase().indexOf('http://') == -1 ) {
          this.iteminfo.webinarLink = 'https://' + this.iteminfo.webinarLink;
        }
        this.joinlink = environment.cronosApiUrl + '/webinars/' + this.token + '/join'
        this.getExpiryTime();
        this.loadOfferPage();
        this.loadingInfo = false;
      });

    }
  }

  openWebinarLink() {
    window.open(this.joinlink ,'_blank')
  }

  openShare() {
    this.clipboard.copyFromContent(window.location.href);
    this.snackbar.openSnackBar("Link in der Zwischenablage", null, 10000, false);
  }


  redirectToOfferPage() {
    if (this.offerPageUrl != null && this.offerPageUrl.length > 0) {
      let dialogRef = this.dialog.open(NoTokenRedirectDialogComponent, {
        data: this.offerPageUrl,
        maxWidth: '100%',
        width: '600px'
      });
    } else this.redirectToOfferPage();
  }

  loadOfferPage() {
    this.publicOfferPageResource.getFormatPageForSyncedFormat({syncedFormatId: this.cronosFormatId}).then(res => {
      this.offerPageId = res.offerPageId;
      this.offerPageUrl = res.routingUrl;
      this.loadUpsells();
    })
  }

  loadUpsells() {
    this.publicOfferPageResource.getOfferPageUpsellRecommendations(this.offerPageId).then(upsells => {
      this.recommendedUpsellPageIds = upsells;
    });
  }

  getExpiryTime() {
    // generate date string to fix weird IE error... don't ask
    let year = this.iteminfo.date.toString().substring(0, 4)
    let month = this.iteminfo.date.toString().substring(5, 7)
    let day = this.iteminfo.date.toString().substring(8, 10)
    let hours = this.iteminfo.date.toString().substring(11, 13)
    let minutes = this.iteminfo.date.toString().substring(14, 16)
    let expiryDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes))

    let timeLeft = expiryDate.getTime() - new Date().getTime();

    if (timeLeft < 0) {
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
      this.days = 0;
      this.months = 0;
      this.webinarIsJoinable = true;
    } else {

      const tday = 1000 * 60 * 60 * 24;
      const thour = 1000 * 60 * 60;
      const tminute = 1000 * 60;
      const tsecond = 1000;

      this.days = Math.floor(timeLeft / tday);
      timeLeft -= this.days * tday

      this.hours = Math.floor(timeLeft / thour);
      timeLeft -= this.hours * thour

      this.minutes = Math.floor(timeLeft / tminute);
      timeLeft -= this.minutes * tminute

      this.seconds = Math.floor(timeLeft / tsecond);
      timeLeft -= this.seconds * tsecond

      if (this.days == 0 && this.hours == 0 && this.minutes < 15) { // user is allowed to join webinar only 15 min before appointment
        this.webinarIsJoinable = true;
      }
    }
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }

  getParams() {
    return {
      oid: null,
      fid: this.cronosFormatId,
      sid: this.seminarId,
      waitingList: this.waitingListSignup,
      lsActive: this.localSplitWasActive
    };
  }


  /*Phone number validation*/

  requestingAuthCode = false;
  codeRequested = false;

  requestAuthCode() {
    if(!this.phoneNumberControl.valid)return
    let parsedPhoneNumber = this.phoneUtil.parseAndKeepRawInput(this.phoneNumberControl.value)
    this.requestingAuthCode = true;
    this.contactAPIResourceV2.sendAuthenticationSmsToVerifyContactPhoneNumber({
      code: this.token,
      phoneNumber: this.phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
    }).then(() => {
      this.requestingAuthCode = false;
      this.codeRequested = true;
      this.snackbar.openSnackBar("Dein Code ist unterwegs!", 'Ok', 3000, false)
    });
  }

  loadPhoneNumber() {
    this.contactAPIResourceV2.getContactAuthenticationInformationByToken({code: this.token}).then(result => {
      if (result.value) {
        this.phoneNumberControl.setValue(result.value);
      }

    })
  }

  checkCode() {
    this.checkingCode = true;
    if (!this.codeInputFromUser.valid) return

    this.actionLinkResource.validatePhoneNumberByPromRegStateCode({code: this.token, authCode: this.codeInputFromUser.value}).then(result => {
      this.checkingCode = false
      if (!result.validated) {
        this.snackbar.openSnackBar("Verifizierung fehlgeschlagen! Dein Code ist ungültig", "Ok", 3000, true)
        this.allowedToJoin = false
      } else {
          this.allowedToJoin = true;
          this.step = 'enterWebinar';
          this.snackbar.openSnackBar("Verifizierung erfolgreich!", 'Ok', 3000, false);
          this.updateTripleOptInInDataLayerAndTriggerPageView();
      }
    })
  }

  updateTripleOptInInDataLayerAndTriggerPageView() {
    this.analyticsService.reportTripleOptInStatus(true, this.route.snapshot.url[this.route.snapshot.url.length -1].path)
    // this.analyticsService.reportPageView()
    this.analyticsService.reportPageViewEvent()
  }

  closeFullnameNotification(){
    this.fadeOutNotification = true
    //Delay for fade out animation
    delay(400).then(() => {
      this.showFullnameNotification = false
    })
  }

  delayCountDown: number = 3

  showHintAndNavigateToWebinar(){
    this.step = 'delayForHint'

    delay(1000).then(() => {
      this.delayCountDown = 2
      delay(1000).then(() => {
        this.delayCountDown = 1
        delay(1000).then(() => {
          this.delayCountDown = 0
          delay(500).then(() => {
            this.openWebinarLink()
          })
        })
      })
    })
  }
}
