import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GeoLocation, LocalStorageService} from "../../../local-storage.service";
import {GlobalCheckoutService} from "../../global-checkout.service";
import {PublicLocationData} from '../../../generated/cronos/data';
import {PublicStructureResourceV2} from '../../../generated/cronos/resources';

@Component({
  selector: 'app-location-select-v1',
  templateUrl: './location-select-v1.component.html',
  styleUrls: ['./location-select-v1.component.scss']
})
export class LocationSelectV1Component implements OnInit {

  searchQuery = "";

  locations: PublicLocationData[] = [];
  closeLocations: PublicLocationData[] = [];

  locationCalculationState: "Uninitialized" | "Initiated" | "Finished" | "PermissionDenied"|"UnknownError" = "Uninitialized";
  loading = true;

  geoLocationSupported = false;

  frownAnimationFinished = false;

  geoLocation: GeoLocation;

  @Output()
  locationSelected: EventEmitter<any> = new EventEmitter<any>()

  constructor(public globalCheckoutService: GlobalCheckoutService, private localStorageService: LocalStorageService, private cronosStructureApi: PublicStructureResourceV2) { }

  ngOnInit(): void {
    this.loadLocations();
  }

  selectLocation(location: PublicLocationData){
    this.globalCheckoutService.updateZipCodeOnUser(location.zipCode)
    this.globalCheckoutService.updateLocationOnUser(location)
    this.locationSelected.emit()
  }


  loadLocations() {
    this.cronosStructureApi.getLocations().then(res => {
      this.locations = res;
      this.loading = false;
      this.checkLocationPermissionStatusAndProceedIfPossible();
    })
  }

  checkLocationPermissionStatusAndProceedIfPossible() {
    if(navigator.geolocation){
      this.geoLocationSupported = true;
    }

    if(this.localStorageService.getGeoLocationGranted() && this.geoLocationSupported){
      //this.getLocationAndCalculate();
    }

  }

}
